<template>
  
 <div class="view-policy"> 
   
   <base-content-wrapper-new>
     <base-icon app="constlet" size="xl">constlet</base-icon>
     <base-title size="m" mB="s">Terms & Conditions for all Constlet Apps</base-title>
     <br><br>
     TLDR: <b>We offer simple applications that generally do not require any or many terms & conditions.</b>
     <p>By accessing or using the service, you agree to be bound by these Terms. 
      If you disagree with any part of these Terms, you may not access the service.
     </p>      
     
     <b>1. Subscription</b>
     <p>
      Our service offers monthly and annual subscriptions through Apple and Play stores.
     </p>
     <b>2. User Responsibilities</b>
     <p>
      You must be of legal age to use the service or have parental/guardian consent if you are a minor.
     </p>
     <b>3. Intellectual Property</b>
     <p>
      The service and its content are the exclusive property of Constley Ltd (Constlet Oy ). 
      You may not use our trademarks without permission.
     </p>     
     <b>4. Third-party services, links or providers or alike</b>
     <p>
      We are not responsible for any of third-party services, links or providers or alike linked within the service.
     </p>
     
     <b>5. Termination</b>
     <p>
       You have the right to terminate the service following the Apple and Play store utilities.
       We reserve the right to terminate or suspend your account for any reason, without prior notice.
     </p>   

     <b>6. Limitation of Liability</b>
     <p>
      Our liability is limited to the amount paid for the service. 
      We are not liable for any indirect or consequential damages.
     </p>  

     <b>7. Governing Law</b>
     <p>
      These Terms are governed by the laws of Finland.
     </p>  

     <b>8. Changes to Terms</b>
     <p>
      We reserve the right to modify these Terms at any time. 
      By continuing to use the service, you agree to the revised Terms.
     </p>  
   </base-content-wrapper-new>
  
 </div>
</template>

<script>

export default {
 name: "termsConditions",

 head: {
   title: {
     inner: 'Constlet Apps Terms & Conditions'
   }
 }
};
</script>

<style lang="scss" scoped>
.view-policy {
 padding: 1em;
}
</style>
